import React, { useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import { graphql, StaticQuery } from "gatsby";
import { IReferralPage, IUsersByReferralCode } from "../interface";
import {
  queryListUsersReferralDetails,
  ResponseMessage,
} from "../../api/graphqlAPI";
import { googleAnalyticsSendEvent } from "../../services/analytics";
import TitleSection from "./titleSection";
import ContentSection from "./contentSection";
import GiftCardSection from "./giftCardSection";
import ReferralsPlaceHolder from "./placeholder";

const Referral = ({ referralCode }: { referralCode: string | null }) => {
  const [userReferralDetails, setUserReferralDetails] =
    useState<IUsersByReferralCode>();
  const graphqlApi = process.env.GATSBY_SANTA_GRAPHQL_API;
  const graphqlXApiKey = process.env.GATSBY_SANTA_X_API_KEY;
  const areKeysDefined = graphqlApi && graphqlXApiKey;

  useEffect(() => {
    if (areKeysDefined && referralCode) {
      const fetchListUsersReferralDetails = async () => {
        const data = await queryListUsersReferralDetails(referralCode);
        if (data === ResponseMessage.SERVER_ERROR) {
          navigate("/");
        } else {
          const userReferralData = data as IUsersByReferralCode;
          if (userReferralData.usersByReferralCode?.items.length > 0) {
            setUserReferralDetails(userReferralData);
            googleAnalyticsSendEvent("event", "Referral Screen Event");
          } else {
            navigate("/");
          }
        }
      };

      fetchListUsersReferralDetails();
    } else {
      navigate("/");
    }
  }, [referralCode]);

  const renderFn = (referralContent: IReferralPage) => {
    return (
      <>
        {userReferralDetails ? (
          <div className="flex flex-col min-h-screen h-full">
            <TitleSection
              headline={referralContent.contentfulReferral.headline}
              position={
                referralContent.contentfulReferral.userReferralNamePosition
              }
              userReferralName={
                userReferralDetails.usersByReferralCode.items[0].firstName
              }
            />
            <GiftCardSection />
            <ContentSection
              referralContent={referralContent}
              referralCode={referralCode || ""}
            />
          </div>
        ) : (
          <ReferralsPlaceHolder />
        )}
      </>
    );
  };

  return (
    <StaticQuery
      query={graphql`
        {
          contentfulReferral {
            headline
            userReferralNamePosition
            subContent {
              raw
            }
            underText {
              raw
            }
            inputPlaceholder
            beforeAttributeSendPlaceholder
            afterAttributeSendPlaceholder
            serverErrorMessage
            invalidNumberResponseFromServer
            invalidNumberResponseFromLocal
          }
        }
      `}
      render={renderFn}
    />
  );
};

export default Referral;
