import React, { ReactNode } from "react";
import {
  INLINES,
  BLOCKS,
  MARKS,
  Block,
  Inline,
} from "@contentful/rich-text-types";

export const optionReferralSubContent = {
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      return (
        <a className="text-cyan-500" href={node.data.uri}>
          {children}
        </a>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
      return <div className="mb-10">{children}</div>;
    },
  },
};

export const optionReferralUnderText = {
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      return (
        <a className="text-cyan-500" href={node.data.uri}>
          {children}
        </a>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
      return <span className="w-10/12">{children}</span>;
    },
  },
};
