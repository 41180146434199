import React, { ReactNode } from "react";

const ButtonPlacement = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex items-center justify-center w-10/12 max-w-[327px] font-bold bg-white h-14 rounded-full mt-12">
      {children}
    </div>
  );
};

export default ButtonPlacement;
