import React from "react";
import Lottie from "lottie-react";
import LoaderSettings from "../assets/animations/LoaderSettings.json";

const Loader = () => {
  return (
    <Lottie
      className="w-[70px]"
      animationData={LoaderSettings}
      loop={true}
      autoPlay={true}
    />
  );
};

export default Loader;
