import { IUsersByReferralCode } from "../components/interface";
import {
  queryUsersByReferralCode,
  queryMutationAttributeReferral,
} from "./queries";

export enum ResponseMessage {
  SERVER_ERROR = "Server Error",
  INVALID_NUMBER_ERROR = "Invalid Number",
  SUCCESS_MESSAGE = "smsSent",
}

export const queryListUsersReferralDetails = async (referralCode: string) => {
  try {
    const data = await fetch(process.env.GATSBY_SANTA_GRAPHQL_API as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.GATSBY_SANTA_X_API_KEY as string,
      },
      body: JSON.stringify({
        query: queryUsersByReferralCode(referralCode),
      }),
    });
    const jsonData = await data.json();
    return jsonData.data as IUsersByReferralCode;
  } catch (error) {
    return ResponseMessage.SERVER_ERROR;
  }
};

export const mutationAttributeReferral = async (
  inputValue: string,
  referralCode: string
) => {
  try {
    const data = await fetch(process.env.GATSBY_SANTA_GRAPHQL_API as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.GATSBY_SANTA_X_API_KEY as string,
      },
      body: JSON.stringify({
        query: queryMutationAttributeReferral(inputValue, referralCode),
      }),
    });
    const jsonData = await data.json();
    if (jsonData.errors !== undefined) {
      return ResponseMessage.INVALID_NUMBER_ERROR;
    } else {
      return ResponseMessage.SUCCESS_MESSAGE;
    }
  } catch (error) {
    return ResponseMessage.SERVER_ERROR;
  }
};
