import React from "react";
import {
  mutationAttributeReferral,
  ResponseMessage,
} from "../../api/graphqlAPI";
import { googleAnalyticsSendEvent } from "../../services/analytics";

const PHONE_REGEX = /^\+?[0-9]{10,15}$/;

const checkValidValue = (phoneNumberToValidate: string) => {
  return (
    phoneNumberToValidate.length > 20 ||
    phoneNumberToValidate.length === 0 ||
    !PHONE_REGEX.test(phoneNumberToValidate)
  );
};

const ReferralButton = ({
  phoneNumber,
  currentReferralCode,
  setInvalidNumber,
  setIsLoading,
  setSmsSent,
  setErrorMessage,
  placeholder,
  invalidNumber,
  serverErrorMessage,
  invalidNumberFromLocal,
  invalidNumberFromServer,
}: {
  phoneNumber: string;
  currentReferralCode: string;
  setInvalidNumber: (value: React.SetStateAction<boolean>) => void;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setSmsSent: (value: React.SetStateAction<boolean>) => void;
  setErrorMessage: (value: React.SetStateAction<string>) => void;
  placeholder: string;
  invalidNumber: boolean;
  serverErrorMessage: string;
  invalidNumberFromLocal: string;
  invalidNumberFromServer: string;
}) => {
  const graphqlApi = process.env.GATSBY_SANTA_GRAPHQL_API;
  const graphqlXApiKey = process.env.GATSBY_SANTA_X_API_KEY;
  const areKeysDefined = graphqlApi && graphqlXApiKey;

  const submitReferralPhoneNumber = async () => {
    setIsLoading(true);
    const data = await mutationAttributeReferral(
      phoneNumber,
      currentReferralCode
    );
    if (data === ResponseMessage.INVALID_NUMBER_ERROR) {
      googleAnalyticsSendEvent("event", ResponseMessage.INVALID_NUMBER_ERROR);
      setInvalidNumber(true);
      setIsLoading(false);
      setErrorMessage(invalidNumberFromServer);
    } else if (data === ResponseMessage.SUCCESS_MESSAGE) {
      googleAnalyticsSendEvent("event", "SMS Sent");
      setSmsSent(true);
      setIsLoading(false);
    } else if (data === ResponseMessage.SERVER_ERROR) {
      googleAnalyticsSendEvent("event", ResponseMessage.SERVER_ERROR);
      setIsLoading(false);
      setErrorMessage(serverErrorMessage);
    }
  };

  const submitNumber = async () => {
    googleAnalyticsSendEvent("event", "Download App Clicked");
    if (checkValidValue(phoneNumber)) {
      setInvalidNumber(true);
      setErrorMessage(invalidNumberFromLocal);
    } else {
      if (areKeysDefined) {
        await submitReferralPhoneNumber();
      }
    }
  };

  return (
    <button
      onClick={submitNumber}
      disabled={invalidNumber}
      className={`w-10/12 max-w-[327px] text-black font-bold bg-white h-14 rounded-full hover:bg-gray-200 focus:outline-none ${
        invalidNumber ? "mt-3" : "mt-12"
      }`}
    >
      {placeholder}
    </button>
  );
};

export default ReferralButton;
