const desktopCover = "website/desktopCover_v1hj7g_ugevcw.webp";
const mobileCover = "website/mobileCover_reymg1.png";
const desktopDogInBag = "website/desktopDogInBag_jsghtp.webp";
const mobileDogInBag = "website/mobileDogInBag_it62ik.png";
const bikes = "website/bikes_yvu94i.webp";
const candle = "website/candle_qly3fm.webp";
const desktopWoman = "website/desktopWoman_z7bks6.webp";
const mobileWoman = "website/mobileWoman_hutxvw.webp";
const galleryLeSpecsPrinceSunglasses =
  "website/461_le_specs_prince_sunglasses_xn6yaf.webp";
const galleryPublicGoodsOrganicGroundCoffee =
  "website/1575_public_goods_organic_ground_coffee_hxtm3b.webp";
const gallerySundayCitizenTulumThrow =
  "website/1596_sunday_citizen_tulum_throw_tvscsn.webp";
const galleryPFCandleCoBlackFig =
  "website/372_pf_candle_co_black_fig_pfcgnc.webp";
const galleryAlleyoopMultiMoodLipSet =
  "website/1600_alleyoop_multi_mood_lip_set_az1r84.webp";
const galleryTangramSmartropeLed =
  "website/391_tangram_smartrope_led_t0qh1h.webp";
const galleryChanceSebastianBasketball =
  "website/1562_chance_sebastian_basketball_dfyr8m.webp";
const galleryEcoKidsFingerPaint =
  "website/89_eco_kids_finger_paint_vscqoc.webp";
const galleryLegacySidecarBarToolSet =
  "website/1783_legacy_sidecar_bar_tool_set_mbqsg8.webp";
const galleryTostNonAlcoholicRefresher =
  "website/1747_tost_non_alcoholic_refresher_twyxl3.webp";
const galleryFellowCarterEverywhereMug =
  "website/1640_fellow_carter_everywhere_mug_zfsud7.webp";
const galleryKinfieldSosRescueMask =
  "website/634_kinfield_sos_rescue_mask_il4olh.webp";
const giftCard = "website/giftCard_hifomz.webp";

export const assets: { [key: string]: string } = {
  desktopCover,
  desktopDogInBag,
  desktopWoman,
  mobileCover,
  mobileDogInBag,
  mobileWoman,
  candle,
  bikes,
  galleryLeSpecsPrinceSunglasses,
  galleryPublicGoodsOrganicGroundCoffee,
  gallerySundayCitizenTulumThrow,
  galleryPFCandleCoBlackFig,
  galleryAlleyoopMultiMoodLipSet,
  galleryTangramSmartropeLed,
  galleryChanceSebastianBasketball,
  galleryEcoKidsFingerPaint,
  galleryLegacySidecarBarToolSet,
  galleryTostNonAlcoholicRefresher,
  galleryFellowCarterEverywhereMug,
  galleryKinfieldSosRescueMask,
  giftCard,
};
