export const googleAnalyticsSendEvent = (event: string, content: string) => {
  if (typeof window !== `undefined`) {
    if (window.gtag != null) {
      window.gtag(event, content);
    }
  }
};

export const facebookPixelAnalyticsSendEvent = (
  event: string,
  content: string
) => {
  if (typeof window !== `undefined`) {
    if (window.fbq != null) {
      window.fbq(event, content);
    }
  }
};
