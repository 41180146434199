export const queryUsersByReferralCode = (referralCode: string) => {
  return `
    query UsersByReferralCode {
      usersByReferralCode(referralCode: "${referralCode}") {
        items {
          firstName
        }
      }
    }
  `;
};

export const queryMutationAttributeReferral = (
  phoneNumber: string,
  referralCode: string
) => {
  return `
    mutation AttributeReferral {
      attributeReferral(input: {phoneNumber: "${phoneNumber}", referralCode: "${referralCode}"})
    }
  `;
};
