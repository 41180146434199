import * as React from "react";
import Referral from "../components/referral";
import { PageProps } from "gatsby";

const ReferralPage = ({ location, params }: PageProps) => {
  const queryStringParams = new URLSearchParams(location.search);
  const referralCode = params["referralCode"] || queryStringParams.get("code");
  return <Referral referralCode={referralCode} />;
};

export default ReferralPage;
