import React from "react";
import Logo from "../../../assets/logo.svg";
import "./style.css";

const ReferralsPlaceholder = () => {
  return (
    <div className="referrals-placeholder-bg-gradient flex flex-col items-center px-6 min-h-screen">
      <div className="max-w-lg min-w-[280px] flex flex-col items-center w-full text-center min-h-screen">
        <Logo className="h-9 w-28 mt-12 mb-12" />
      </div>
    </div>
  );
};

export default ReferralsPlaceholder;
