import React, { ChangeEvent, useState } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import {
  optionReferralSubContent,
  optionReferralUnderText,
} from "../../../services/contentful-options";
import Invalid from "../../../assets/invalid.svg";
import ReferralErrorMessage from "../ReferralErrorMessage";
import Loader from "../../Loader";
import ReferralButton from "../ReferralButton";
import ButtonPlacement from "../ButtonPlacement";
import { IReferralPage } from "../../interface";
import Success from "../../../assets/success.svg";
import "./style.css";

type Props = {
  referralCode: string;
  referralContent: IReferralPage;
};

const ContentSection = ({ referralCode, referralContent }: Props) => {
  const [invalidNumber, setInvalidNumber] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);
  const [smsSent, setSmsSent] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
    setInvalidNumber(false);
  };

  const resetNumber = () => {
    setPhoneNumber("");
    setInvalidNumber(false);
  };

  return (
    <div className="referrals-content-bg-gradient flex flex-col items-center px-6 flex-grow flex-shrink basis-auto">
      <div className="max-w-lg min-w-[280px] flex flex-col items-center w-full text-center">
        {renderRichText(
          referralContent.contentfulReferral.subContent,
          optionReferralSubContent
        )}
        <div className="relative flex items-center w-10/12 max-w-[327px]">
          <input
            type="tel"
            id="phone"
            name="phone"
            onChange={handleInputChange}
            value={phoneNumber}
            className="w-full text-black rounded-md bg-white text-base h-14 pl-4 placeholder:text-slate-500"
            placeholder={referralContent.contentfulReferral.inputPlaceholder}
          />
          {invalidNumber && (
            <button
              className="absolute h-fit w-fit right-4"
              onClick={resetNumber}
            >
              <Invalid className="h-6 w-6" />
            </button>
          )}
        </div>

        {invalidNumber && <ReferralErrorMessage errorInput={errorMessage} />}

        {loading ? (
          <ButtonPlacement>
            <Loader />
          </ButtonPlacement>
        ) : !smsSent ? (
          <ReferralButton
            phoneNumber={phoneNumber}
            currentReferralCode={referralCode}
            setInvalidNumber={setInvalidNumber}
            setIsLoading={setIsLoading}
            setSmsSent={setSmsSent}
            placeholder={
              referralContent.contentfulReferral.beforeAttributeSendPlaceholder
            }
            invalidNumber={invalidNumber}
            setErrorMessage={setErrorMessage}
            serverErrorMessage={
              referralContent.contentfulReferral.serverErrorMessage
            }
            invalidNumberFromLocal={
              referralContent.contentfulReferral.invalidNumberResponseFromLocal
            }
            invalidNumberFromServer={
              referralContent.contentfulReferral.invalidNumberResponseFromServer
            }
          />
        ) : (
          <ButtonPlacement>
            <Success className="h-6 w-6" />
            <span className="text-black ml-2">
              {referralContent.contentfulReferral.afterAttributeSendPlaceholder}
            </span>
          </ButtonPlacement>
        )}

        <div className="mt-16 mb-8 w-full bottom-0">
          {renderRichText(
            referralContent.contentfulReferral.underText,
            optionReferralUnderText
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
