import React from "react";
import { AdvancedImage, placeholder } from "@cloudinary/react";
import { assets } from "../../../assets";
import cloudinaryInstance from "../../../services/cloudinaryClient";
import "./style.css";

const GiftCardSection = () => {
  return (
    <div className="referrals-gift-card-bg-gradient flex flex-col items-center px-6 flex-grow-0 flex-shrink basis-auto">
      <div className="max-w-lg min-w-[280px] flex flex-col items-center w-full text-center">
        <AdvancedImage
          cldImg={cloudinaryInstance.image(assets["giftCard"])}
          plugins={[placeholder({ mode: "blur" })]}
        />
      </div>
    </div>
  );
};

export default GiftCardSection;
