import React from "react";
import Logo from "../../../assets/logo.svg";
import "./style.css";

const headlineGenerator = (
  headline: string,
  position: number,
  userReferralName: string
) => {
  const headlineSplitHelper = headline.split(" ");
  headlineSplitHelper.splice(position - 1, 0, userReferralName);
  return headlineSplitHelper.join(" ");
};

type Props = {
  headline: string;
  position: number;
  userReferralName: string;
};

const TitleSection = (props: Props) => {
  return (
    <div className="referrals-title-bg-gradient flex flex-col items-center px-6 flex-grow-0 flex-shrink basis-auto">
      <div className="max-w-lg min-w-[280px] flex flex-col items-center w-full text-center">
        <Logo className="h-9 w-28 mt-12 mb-12" />
        <div className="font-black text-white mb-12 italic text-4xl sm:text-5xl">
          {headlineGenerator(
            props.headline,
            props.position,
            props.userReferralName
          )}
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
