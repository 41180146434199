import { Cloudinary } from "@cloudinary/url-gen";

const cloudinaryInstance = new Cloudinary({
  cloud: {
    cloudName: "santainc",
  },
  url: {
    secureDistribution: "res.cloudinary.com",
    secure: true,
  },
});

Object.freeze(cloudinaryInstance);

export default cloudinaryInstance;
